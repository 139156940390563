import React, { useState, useEffect} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTrashCan,
  faCircleArrowLeft,
  faCircleArrowRight,
} from "@fortawesome/free-solid-svg-icons";
import { useAuth } from "../utils/auth";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import config from "../config/config";
import {useParams} from "react-router-dom";





function AdminUsers() {
  const [users, setUsers] = useState([]);
  const [chunkId, setChunkId] = useState(1);
  const [chunkSize, setChunkSize] = useState(1);
  const auth = useAuth();
  const [admin, setAdmin] = useState({})
  const { adminId } = useParams(); 
  const USERS_URL = `${config.apiBaseUrl}adminroute/admin/${adminId}/users`;
  

  const fetchUser = async () => {
    try {
      const res = await axios({
        method: "GET",
        url: USERS_URL,
        headers: {
          authorization: auth.user.token,
        }
      });
      const data = res.data;
      if (data.pages !== -1) {
        setChunkSize(data.pages);
      }
      setUsers(data);
    } catch (error) {
      toast.error(error.message || 'An Error Occured , Please Try Again');
    }
  };

  useEffect(() => {
    if (admin) { 
      fetchUser();
    }
  }, [chunkId, admin]); 

  return (
    <>
      <div className="px-6 py-0 my-4">
        <div className="w-full place-center relative overflow-x-auto shadow-md sm:rounded-lg content-center m-2">
          <table className="w-full text-sm text-left text-gray-500 m-2 p-2">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50">
              <tr>
                <th score="col" className="px-4 py-3">
                  All{" "}
                  <input
                    type="checkbox"
                    className="form-check-input"
                    name="allSelect"
                    checked={
                      Array.isArray(users) && 
                      users.filter((user) => user?.isChecked !== true).length === 0
                    }
                 
                  />
                </th>
                <th scope="col" className="px-4 py-3">
                  Name
                </th>
                <th scope="col" className="px-4 py-3">
                  Email
                </th>
                <th scope="col" className="px-4 py-3">
                  UserLimit
                </th>
                <th scope="col" className="px-4 py-3">
                  Role
                </th>
           
                <th scope="col" className="px-4 py-3">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
            
              {users.map((curUser) => {
                return (
                  <tr
                    key={curUser.id}
                    className="bg-white border-b hover:bg-gray-50"
                  >
                    <td
                      scope="row"
                      className="px-6 py-4 font-medium text-gray-900 whitespace-nowra"
                    >
                      <input
                        className="form-check-input"
                        type="checkbox"
                        name={curUser.email}
                        checked={curUser?.isChecked || false}
                        
                      />
                    </td>
                    <td className="px-3 py-4">{curUser.userName}</td>
                    <td className="px-3 py-4">{curUser.userEmail}</td>
                    <td className="px-3 py-4">{curUser.userDepartment}</td>
                    <td className="px-3 py-4">{curUser.userRegion}</td>
                    <td className="px-7 py-4">
                      <button>
                        <FontAwesomeIcon icon={faTrashCan} size="xl" />
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
        <div className="flex ">
          <div className="flex space-x-3 items-center px-3">
            <div>
              Page:
            </div>
            <div>
              <button
                onClick={() => {
                  if (chunkId > 1) {
                    setChunkId(chunkId - 1);
                  }
                }}
                disabled={chunkId <= 1}
              >
                <FontAwesomeIcon icon={faCircleArrowLeft} size="xl" />
              </button>
            </div>
            <div className="font-medium text-gray-500 whitespace-nowra">
              {chunkId}/{chunkSize}
            </div>
            <div>
              <button
                onClick={() => {
                  if (chunkId < chunkSize) {
                    setChunkId(chunkId + 1);
                  }
                }}
                disabled={chunkId >= chunkSize}
              >
                <FontAwesomeIcon icon={faCircleArrowRight} size="xl" />
              </button>
            </div>
          </div>
        </div>
        <ToastContainer
          position="top-center"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
        />
      </div>
    </>
  );
}

export default AdminUsers;
