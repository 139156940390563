import { useState } from "react";
import Login from "./pages/Login";
import "./App.css";
import DashBoard from "./pages/DashBoard";
import Users from "./pages/Users";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Campaign from "./pages/Campaign";
import CampaignRecords from "./pages/CampaignRecords";
import CampaignDetails from "./pages/CampaignDetails";
import Charts from "./pages/Charts";
import Phished from "./pages/Phished";
import Phished_TTL from "./pages/Phished_TTL";
import { AuthProvider } from "./utils/auth";
import RequireAuth from "./utils/RequireAuth";
import ExcelFile from "./components/ExcelFile";
import AdminUsers from "./pages/AdminUsers";
import SuperAdmin from "./utils/SuperAdmin";
import Loader from "./components/Loader";
import AdminListPage from "./pages/AdminListPage";
import { UserCredentialsProvider } from "./utils/UserCredentialsContext";
import TwoFactorAuth from "./pages/TwoFactorAuth";
  

// import WebBuilder from "./WebBuilder";

// function App(){
//   return (
//     <WebBuilder/>
//   )
// }

function App() {
  const [isVideoEnded, SetIsVideoEnded] = useState(false);

  const handleVideoEnded = () => {
    SetIsVideoEnded(true);
  }
  return (
    <AuthProvider>
      {!isVideoEnded && <Loader onVideoEnd={handleVideoEnded} />}
      {isVideoEnded &&
        <Router>
          <Routes>
            <Route
              exact
              path="/"
              element={
                <RequireAuth>
                  <DashBoard />
                </RequireAuth>
              }
            />
            <Route
              exact
              path="/"
              element={
                <RequireAuth>
                  <DashBoard />
                </RequireAuth>
              }
            />
            <Route
              exact
              path="/charts"
              element={
                <RequireAuth>
                  <Charts />
                </RequireAuth>
              }
            />
            <Route
              exact
              path="/campaign"
              element={
                <RequireAuth>
                  <Campaign />
                </RequireAuth>
              }
            />
            <Route
              exact
              path="/users"
              element={
                <RequireAuth>
                  <Users />
                </RequireAuth>
              }
            />

            <Route
              path="/campaignrecords/:id"
              element={
                <RequireAuth>
                  <CampaignRecords />
                </RequireAuth>
              }
            />

         
            <Route
              path="/campaigndetails/:uuid/:id"
              element={
                <RequireAuth>
                  <CampaignDetails />
                </RequireAuth>
              }
            />
              <Route
              path="/setup2fa"
              element={
                <RequireAuth>
                  <TwoFactorAuth />
                </RequireAuth>
              }
            />


            <Route exact path="/phished_ttl" element={<Phished_TTL />} />
            <Route exact path="/phished" element={<Phished />} />
            <Route exact path="/login" element={<UserCredentialsProvider><Login /></UserCredentialsProvider>} />
            <Route exact path="/demoexcelfile" element={<ExcelFile />} />
            <Route exact path="/admin" element={<SuperAdmin><AdminListPage /></SuperAdmin>} />
            <Route exact path="/admin/:adminId/users" element={<SuperAdmin><AdminUsers /></SuperAdmin>} />
            
          </Routes>
        </Router>
      }
    </AuthProvider>
  );
}

export default App;
